import React from "react";
import styled from "styled-components";

import Nav from "../common/Nav";
import { StaticImage } from "gatsby-plugin-image";
import BoxButton from "../common/BoxButton";
import HeaderImage from "../../images/waypoint-hero-illustration.png";
const HeaderStyles = styled.div``;

const HeroSection = styled.div`
  height: 635px;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  overflow: hidden;

  background-image: linear-gradient(
    176deg,
    #00aeef 0%,
    #2cb6e8 50%,
    #028ec0 100%,
    #046d8f 100%,
    #08353c 100%
  );
  box-shadow: inset 0 0 14px 18px rgba(34, 34, 34, 0.1);

  .text-section {
    width: 40%;
  }
  @media (max-width: 1050px) {
    height: 450px;
  }
`;

const HeaderText = styled.h1`
  font-size: 136px;
  color: #50bfed;
  -webkit-text-stroke: 2px white;
  font-weight: normal;
  font-family: "Palestina", sans-serif;
  width: 40%;

  @media (max-width: 1500px) {
    font-size: 100px;
  }
  @media (max-width: 1050px) {
    font-size: 75px;
  }

  @media (max-width: 800px) {
    font-size: 100px;
    width: 435px;
  }

  @media (max-width: 450px) {
    font-size: 80px;
    width: 100%;
    text-align: center;
  }
`;

const Image = styled.img`
  width: 50%;
  @media (max-width: 800px) {
    display: none;
  }
`;

const Header = () => {
  return (
    <HeroSection>
      <HeaderText>WAYPOINT WORKS.</HeaderText>
      <Image src={HeaderImage} alt={"hi"} placeholder="none" />
    </HeroSection>
  );
};

export default Header;
