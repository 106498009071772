import React from "react";
import { StaticImage } from "gatsby-plugin-image";
import styled from "styled-components";
import DiagonalHeaderImg from "../../images/diagonal-header.png";
const Section = styled.div`
  display: flex;
  justify-content: space-around;
  margin-left: auto;
  margin-right: auto;
  max-width: 1300px;
`;

const SectionText = styled.div`
  text-align: center;
  margin-right: 50px;
  margin-left: 50px;
  padding-top: 42px;
  position: relative;
  top: -52px;
  background: white;
  font-weight: normal;
`;

const DiagonalHeaderContainer = styled.div`
  position: relative;
  top: -52px;

  img {
    object-fit: contain;
    width: 100%;
    min-height: 100px;
  }
`;
const SectionTitle = styled.div`
  color: #00aeef;
  font-size: 44px;
  text-align: center;
  font-family: "Palestina";
`;

const SectionBody = styled.div`
  font-size: 20px;
  text-align: center;
  font-family: "Lato Light";
`;
const YourDigitalGuide = () => {
  return (
    <Section>
      <DiagonalHeaderContainer>
        <img src={DiagonalHeaderImg} alt={"waypoint header"} />
        <SectionText>
          <SectionTitle>Your digital guide</SectionTitle>

          <SectionBody>
            Our seasoned industry experts have experience with software spanning
            health insurance, eCommerce, IoT, finance, and more. We can handle
            everything from groundbreaking initiatives to app revamps. But
            beyond creating exceptional work, we’re passionate about sharing our
            knowledge and experience, leaving a lasting imact on your team.
            Let's collaborate to achieve your software goals – we're excited to
            guide you there.
          </SectionBody>
        </SectionText>
      </DiagonalHeaderContainer>
    </Section>
  );
};

export default YourDigitalGuide;
