import * as React from "react";
import { Script } from "gatsby";

import Layout from "../components/common/Layout";
import YourDigitalGuide from "../components/home/YourDigitalGuide";
import WhyWaypoint from "../components/home/WhyWaypoint";
import Header from "../components/home/Header";
import WhatWeDo from "../components/home/WhatWeDo";
import ContactForm from "../components/common/ContactForm";

// markup
const IndexPage = () => {
  return (
    <Layout pageTitle="Home Page">
      <Header />
      <YourDigitalGuide />
      <WhatWeDo />
      <WhyWaypoint />
      <ContactForm />
      <Script>{`
          if (window.netlifyIdentity) {
            window.netlifyIdentity.on("init", function() {
              if (!user) {
                window.netlifyIdentity.on("login", function() {
                  document.location.href = "/admin/";
                });
              }
            });
          }
        `}</Script>
    </Layout>
  );
};

export default IndexPage;

export function Head() {
  return (
    <script src="https://identity.netlify.com/v1/netlify-identity-widget.js"></script>
  );
}
