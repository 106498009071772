import React from "react";
import { StaticImage } from "gatsby-plugin-image";
import styled from "styled-components";
import Dots from "../../images/circle-pattern.png";
import WhatWeDoImage from "../../images/what-we-do.jpg";
import SectionHeader from "../common/SectionHeader";

const Section = styled.div`
  margin-left: auto;
  margin-right: auto;
  padding-bottom: 30px;
`;

const SectionBody = styled.div`
  max-width: 1500px;
  display: flex;
  justify-content: space-around;
  align-items: flex-start;
  margin-left: auto;
  margin-right: auto;
`;

const BodyText = styled.div`
  font-family: "Lato Light";
  font-size: 20px;
  padding-left: 50px;
  padding-right: 50px;
  padding-top: 54px;
  width: 100%;
  @media (max-width: 700px) {
    width: 100%;
  }
`;
const BodyHeader = styled.div`
  font-family: "Palestina";
  font-size: 44px;
  color: #00aeef;
`;

const SubHeader = styled.h4`
  font-family: "Palestina";
  font-size: 24px;
  color: #00aeef;
  font-weight: 400;
  margin-top: 30px;
  margin-bottom: 10px;
`;
const ImageSection = styled.div`
  width: 50%;
  position: relative;
  overflow: hidden;
  float: right;
  margin-left: 30px;
  text-align: center;
  @media (max-width: 700px) {
    display: none;
  }
`;

const ListItem = styled.div``;
const WhatWeDo = () => {
  return (
    <Section>
      <SectionHeader text={"WHAT WE DO"} />
      <SectionBody>
        <BodyText>
          <ImageSection>
            <img
              style={{ width: "90%", textAlign: "center" }}
              src={WhatWeDoImage}
              alt={""}
            />
            <img
              style={{
                opacity: 0.8,
                width: "45%",
                height: "auto",
                left: "37px",
                top: "-10%",
                position: "absolute",
              }}
              src={Dots}
              alt={"hi"}
            />
          </ImageSection>
          <ListItem>
            <BodyHeader>Services</BodyHeader>
            At Waypoint our consultants are true software engineers who have
            been there and done that. With their expertise, our consultants can
            quickly get up to speed and make an impact. Whether your project is
            legacy or greenfield, we have the knowledge that will help you chart
            a path to your goals.
          </ListItem>
          <ListItem>
            <SubHeader>TECHNICAL ASSESSMENTS</SubHeader>
            Need an outside perspective on the problems you're facing? Curious
            about the technology you're investing in? We offer technology
            assessments that give you a clear look at the state of your code,
            processes, and architecture.
          </ListItem>
          <ListItem>
            <SubHeader>APP DEVELOPMENT</SubHeader>
            Front-End. Mobile. Back-End. Platform. If its software, we can
            handle it. Every project is different, but our modern software
            approach gives the flexibility to find a solution that fits your
            needs.
          </ListItem>
          <ListItem>
            <SubHeader>REAL-TIME STREAMING DATA</SubHeader>
            In today's world, data is a top priority. At Waypoint we know that
            its not about just having the data, its what you do with it that
            matters.
          </ListItem>
          <ListItem>
            <SubHeader>INDUSTRY EXPERTS</SubHeader>
            Our consultants have seen it all and done it all. With Waypoint
            consultants you know you're in good hands.
          </ListItem>
        </BodyText>
      </SectionBody>
    </Section>
  );
};

export default WhatWeDo;
