import React from "react";
import styled from "styled-components";
import { StaticImage } from "gatsby-plugin-image";
import IntegrateIcon from "../../images/integrate-icon.png";
import AddIcon from "../../images/diamond-icon.png";
import ClockIcon from "../../images/clock-icon.png";
import UtilizeIcon from "../../images/utilize-icon.png";
import HeaderBackground from "../../images/waypoint-pattern-white.png";
import SectionHeader from "../common/SectionHeader";
import { Link } from "gatsby";
const Section = styled.div``;

const SectionBody = styled.div`
  padding-top: 75px;
`;

const SectionBodyHeader = styled.div`
  font-family: "Palestina";
  font-size: 44px;
  color: #00aeef;
  text-align: center;
`;

const BodyText = styled.p`
  font-family: "Lato Light";
  font-size: 20px;
  text-align: center;
  margin-left: auto;
  margin-right: auto;
  max-width: 1300px;
  padding-left: 15px;
  padding-right: 15px;
`;

const Steps = styled.p`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-evenly;
  max-width: 1300px;
  margin-left: auto;
  margin-right: auto;
`;

const IconLabel = styled.div`
  font-family: "Palestina";
  color: #00aeef;
  font-weight: 400;
  font-size: 32px;
  height: 36px;
  margin-bottom: 10px;
`;

const IconSubhead = styled.div`
  font-family: "Lato";
  font-size: 16px;
  width: 300px;
`;

const StepStyle = styled.div`
  padding-top: 20px;
`;
const Step = ({
  label,
  image,
  subhead,
}: {
  label: string;
  image: string;
  subhead: string;
}) => {
  return (
    <StepStyle style={{ textAlign: "center" }}>
      <img
        style={{ alignSelf: "center", height: "196px", width: "196px" }}
        src={image}
      />
      <IconLabel>{label}</IconLabel>
      <IconSubhead>{subhead}</IconSubhead>
    </StepStyle>
  );
};

const WhyWaypoint = () => {
  return (
    <Section>
      <SectionHeader text={"WHY WAYPOINT"} />
      <SectionBody>
        <SectionBodyHeader>We make it easier to navigate</SectionBodyHeader>
        <BodyText>
          The journey of software development can be long and full of pitfalls.
          Our decades of industry experience allow us minimize complications
          that can bog down the process. We’ll help you find the cleanest line
          from point A to point B. Want to know more? Learn more about our
          process <Link to={"about"}>here</Link>
        </BodyText>
        <Steps>
          <Step
            label={"Consistency"}
            subhead={
              "The people you meet will be the people working on your project."
            }
            image={IntegrateIcon}
          />
          <Step
            label={"Quality"}
            subhead={
              "We’re confident in our engineers. So confident we’ll let you try them. "
            }
            image={AddIcon}
          />
          <Step
            label={"Transparency"}
            subhead={
              "We take a pragmatic approach, with real estimates and open communication throughout the project."
            }
            image={UtilizeIcon}
          />
        </Steps>
      </SectionBody>
    </Section>
  );
};

export default WhyWaypoint;
